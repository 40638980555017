/* src/components/TestItemComponent.css */
.test-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }
  
  .word {
    font-size: 3rem;
    margin-bottom: 1.5rem;
  }
  
  .options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }
  
  .option-button {
    background-color: #f0f0f0;
    border: 2px solid #ddd;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s;
  }
  
  .option-button:hover {
    background-color: #e0e0e0;
    transform: translateY(-2px);
  }
  